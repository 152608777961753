import React, { useState, useEffect } from "react";
import { DataTable } from "../../components/tables/index";
import { CommisionDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import { Header } from "../../components/user-dashboard/header";
import { Sidebar } from "../../components/user-dashboard/sidebar";
import moment from "moment";

const CommisionList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  const handleCancel = () => {
    setName("");
    setEmail("");
    setPhone("");
    setFilteredUsers(usersData);
  };

  const handleSearch = () => {
    const filteredData = usersData.filter((student: any) => {
      const nameMatch =
        student.firstName.toLowerCase().includes(name.toLowerCase()) ||
        student.lastName.toLowerCase().includes(name.toLowerCase());

      const emailMatch = email
        ? student.email.toLowerCase().includes(email.toLowerCase())
        : true;

      const phoneMatch = phone
        ? student.phone.includes(phone)
        : true;

      return nameMatch && emailMatch && phoneMatch;
    });

    setFilteredUsers(filteredData);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    const payloadData = {
      isVerify: true,
    };
    AdminService.getManagerConversions(payloadData).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res?.data.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A"),
        }));
        setUsersData(formattedData);
        setFilteredUsers(formattedData);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div>
        <div className="container-fluid">
          <div className="row">
            <Header />
          </div>
          <div className="row">
            <div className="col-md-2 h-100 px-0">
              <Sidebar />
            </div>
            <div className="col-md-10 content-scroll content-bg px-3 py-3">
              <div className="row">
                <div className="col-md-10 mt-3">
                  <label className="form-label d-flex justify-content-start text-start">
                    <b>Conversion list</b>
                  </label>
                  <div className="d-flex">
                    <input
                      name="User Name"
                      onChange={(e: any) => setName(e.target.value)}
                      className="form-control mt-1 me-2"
                      value={name}
                      placeholder="Enter the User Name"
                    />
                    <input
                      type="email" // Email input
                      name="Email"
                      onChange={(e: any) => setEmail(e.target.value)}
                      className="form-control mt-1 me-2"
                      value={email}
                      placeholder="Enter the Email"
                    />
                    <input
                      type="phone" // Phone input
                      name="Phone"
                      onChange={(e: any) => setPhone(e.target.value)}
                      className="form-control mt-1 me-2"
                      value={phone}
                      placeholder="Enter the Phone"
                    />

                    <button
                      className="btn btn-primary rounded px-4 py-1 ms-3"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-secondary rounded ms-2 px-4 py-1"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <DataTable
                  tableData={filteredUsers}
                  TableCols={CommisionDataGridCols}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommisionList;
