import { DataTableCol } from "../../../components/tables/types";

export const StudentsDataGridCols: DataTableCol[] = [
  {
    title: "S.no",
    control: "serialNo",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Date",
    control: "createdAt",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "First Name",
    control: "firstName",
    sortable: true,
    canShowColumn: true,
  },
  // {
  //     title: 'Last Name',
  //     control: 'lastName',
  //     sortable: true,
  //     canShowColumn: true
  // },
//   {
//     title: "Phone",
//     control: "phone",
//     sortable: true,
//     canShowColumn: true,
//   },
  {
    title: "Email",
    control: "email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Domain",
    control: "courseName",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Payment Status",
    control: "status",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Batch",
    control: "batchNameData",
    // sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    sortable: false,
    // isEdit: true,
    isDelete: true,
    isAdd: true,
    canShowColumn: true,
  },
];
