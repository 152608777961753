import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";

import { Form, Button } from "react-bootstrap";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";

const CreateBanner = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    description: "",
    headerImage: null,
    title:''
  });

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
   if (e.target.type === "file") {
      // Handle file input
      const { name, files } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: files[0], // Only handle single file upload
      }));
    } else {
      // Handle standard input fields
      const { name, value } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // Reset errors for the field being changed
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [e?.target?.name]: "",
    }));
  };


  const validate = () => {
    const newErrors: any = {};

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    if (!formData.headerImage) {
      newErrors.headerImage = "Header Image is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);

    formData.userName = data.email;

    if (validate()) {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      AdminService.createHeaderData(formDataToSubmit).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/bannerList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/bannerList");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Header Image</b>
          </h4>
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="headerImage" className="mt-3">
                  <Form.Label>Header Image *</Form.Label>
                  <Form.Control
                    type="file"
                    name="headerImage"
                    onChange={handleInput}
                    className={errors.headerImage ? "is-invalid" : ""}
                  />
                  {errors.headerImage && (
                    <div className="invalid-feedback">{errors.headerImage}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="title" className="mt-3">
                  <Form.Label>Title *</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={handleInput}
                    className={errors.title ? "is-invalid" : ""}
                    placeholder="Enter the Title"
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="description" className="mt-3">
                  <Form.Label>Description *</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    onChange={handleInput}
                    className={errors.description ? "is-invalid" : ""}
                    placeholder="Enter the Description"
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="mt-3">
              <Button
                variant="primary"
                type="submit"
                className="me-2"
                onClick={handleRegister}
              >
                Create
              </Button>
              <Button variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateBanner;
