import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { LoaDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const AdminLoaList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    collegeName: "",
  });

  const handleCancel = () => {
    setName("");
    setEmail("");
    setPhone("");
    setFilteredRegisters(registersData);
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((student: any) => {
      const nameMatch =
        student.firstName.toLowerCase().includes(name.toLowerCase()) ||
        student.lastName.toLowerCase().includes(name.toLowerCase());

      const emailMatch = email
        ? student.email.toLowerCase().includes(email.toLowerCase())
        : true;

      const phoneMatch = phone ? student.phone.includes(phone) : true;

      return nameMatch && emailMatch && phoneMatch;
    });

    setFilteredRegisters(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);
  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllUserDetails(data).then((res: any) => {
      if (res.status === "Success") {
        setRegistersData(res.data);
        setFilteredRegisters(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteAmbassadorInfo = (userId: string) => {
    const payload = {
      userId: userId,
    };
    AdminService.deleteRegister(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleAccess = async (data: any) => {
    let payload = {
      loaEmail: data.email,
      userName: data?.firstName,
      loaCertificatePeriod: new Date(),
      loaCourseName: data?.courseName[0],
      loaStartDate: formData.startDate,
      loaEndDate: formData.endDate,
      collegeName: formData.collegeName,
    };
    try {
      const res = await AdminService.createLoa(payload);
      if (res.status === "Success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error generating loa certificate:", error);
      toast.error("An error occurred while generating the loa certificate.");
    } finally {
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-10 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>LOA Data</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <input
                    type="email" // Email input
                    name="Email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={email}
                    placeholder="Enter the Email"
                  />
                  <input
                    type="phone" // Phone input
                    name="Phone"
                    onChange={(e: any) => setPhone(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={phone}
                    placeholder="Enter the Phone"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1 ms-3"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={LoaDataGridCols}
                deleteAmbassadorInfo={deleteAmbassadorInfo}
                setFormData={setFormData}
                handleAccessData={handleAccess}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoaList;
