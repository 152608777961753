import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { PrivateDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import signImg from "../../../assets/images/img/sign.png";
import compImg from "../../../assets/images/img/Logo.png";
import certifcateImg from "../../../assets/images/user_cerificates/cerificate_img.jpg";
import QRCode from "qrcode"; // Import QRCode library


const PrivateCerificates = () => {
  const [name, setName] = useState("");
  const [registersData, setRegistersData] = useState([]);
  const [filteredRegisters, setFilteredRegisters] = useState([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setFilteredRegisters(registersData); 
  };

  const handleSearch = () => {
    const filteredData = registersData.filter((register: any) =>
      register.name.toLowerCase().includes(name.toLowerCase())
    );
    setFilteredRegisters(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);

  const getRegisters = () => {
    const data = {
      privateCerificate:"adminRelase"
    };
    AdminService.privateCerificate(data).then((res: any) => {
      if (res.status === "Success") {
          setRegistersData(res.data);
          setFilteredRegisters(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const generateQrCode = async (url: any) => {
    try {
      const qrCode = await QRCode.toDataURL(url);
      return qrCode;
    } catch (error) {
      console.error("QR Code generation failed", error);
    }
  };

  const deleteRegisterInfo = (userId: string) => {
    const payload = {
      userId: userId,
    };
    AdminService.deleteRegister(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleCreateRole = () => {
    navigate('/admin/createPrivateCerificate')
  };

  const handleAccessCerificate=async(data:any)=>{
  let certificateHtml;
  const certificateUrl = `https://www.eduexpose.in/verifyCerificate`;

  // Generate QR Code and wait for it to be ready
  const dataUrl = await generateQrCode(certificateUrl);

  switch (data?.certificateType[0]) {
    case "InternshipCertificate":
      certificateHtml = ReactDOMServer.renderToString(
        IntenshipT(data, dataUrl)
      );
      break;
    default:
      certificateHtml = "";
  }

  const container1 = document.createElement("div");
  container1.style.position = "fixed";
  // container1.style.top = "-10000px";
  // container1.style.left = "-8000px";
  // container1.style.width = "100%";
  // container1.style.minHeight = "100%";
  container1.style.padding = "0px";
  // container1.style.margin = "auto";
  container1.innerHTML = certificateHtml;
  document.body.appendChild(container1);

  await new Promise((resolve) => setTimeout(resolve, 1000));

  // Render the HTML content to canvas
  const canvas = await html2canvas(container1, {
    scale: 2, // Higher scale for better resolution
    useCORS: true, // Enable cross-origin images loading
    scrollX: 0,
    scrollY: 0,
    width: container1.scrollWidth,
    height: container1.scrollHeight,
  });

  // Clean up the temporary HTML container immediately
  document.body.removeChild(container1);

  // Convert canvas to image data
  const imgData = canvas.toDataURL("image/png");

  // Initialize jsPDF with custom dimensions
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "px",
    format: [canvas.width, canvas.height], // Set dimensions to match canvas
  });

  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  // Add the image to the PDF
  pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);

  // Save the PDF
  pdf.save(`${data.userName}_certificate.pdf`);
  }
  const IntenshipT = (item: any, dataUrl: any) => {
    const date = new Date(item.createdAt).toLocaleDateString();
    const startDate = new Date(item.startDate).toLocaleDateString();
    const endDate = new Date(item.endDate).toLocaleDateString();
    const gender = item?.gender?.toLowerCase();
    const capitalizedGender = gender.charAt(0).toUpperCase() + gender.slice(1);
    return (
      <>
        <div className="main-section">
          <div className="container pt-5 pb-5">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8 ps-5">
                <h1 className="head-1 pt-5 mb-0">CERTIFICATE</h1>
                <span className="op-7 head-3">of Internship</span>
                <h5 className="op-7 para">Proudly awarded to</h5>
                <h1 className="head-2 pt-2 pb-2">{item.userName}</h1>

                <span className="pt-5">
                  {capitalizedGender} successfully completed program in
                  <b> {item.courseName}</b> from{" "}
                  <b>
                    {startDate} to {endDate}
                  </b>
                  . We found {item.gender} sincere, hardworking, dedicated and
                  result oriented. We take this opportunity to wish{" "}
                  {item.gender} all the best for future.
                </span>
                <div className="d-flex justify-content-between mt-5 pt-5">
                  <div>
                    <div>
                      <img src={signImg} width="150px" alt="" />
                    </div>
                    <div className="sign">
                      <span className="op-7 mt-2">Director</span>
                    </div>
                  </div>
                  <div>
                    <div className="">
                      <div className="text-center">
                        <img src={dataUrl} width="80px" alt="QR-code" />
                      </div>
                      <div className="text-center">
                        <h6 className="op-7 mb-0">Scan to validate</h6>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 pt-2">
                    <div className="">{date}</div>

                    <div className="sign">
                      <span className="op-7">Date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <h5>
                    <b>Cerificates List</b>
                  </h5>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button className="btn btn-success" onClick={handleCreateRole}>
                    Create Cerificate
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredRegisters}
                TableCols={PrivateDataGridCols}
                deleteRegisterInfo={deleteRegisterInfo}
                handleAccessCerificate={handleAccessCerificate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateCerificates;
