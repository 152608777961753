import { DataTableCol } from "../../../components/tables/types";

export const PrivateDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'userName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Course Name',
        control: 'courseName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'certificateEmail',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Access',
        control: 'cAccess',
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        canShowColumn: true
    },
];