import React, { useState, useEffect } from "react";
import { DataTable } from "../../components/tables/index";
import { ConversionsDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../app/service/admin.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";

const ConversionList = () => {
  const [name, setName] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    // setFilteredUsers(usersData);
    // getUsers();
  };

  const handleSearch = () => {
    // Decode the search term (email or phone) before sending it to the backend
    const decodedSearchTerm = decodeURIComponent(name.trim()); // Decode and trim the search term

    const data = {
      search: decodedSearchTerm, // Send the decoded and trimmed search term
      start: 1, // Set pagination start (adjust if needed)
      count: 10, // Set pagination count
    };


    AdminService.getConversionList(data).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res?.data?.result.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A"),
        }));

        setUsersData(formattedData);
      } else {
        toast.error(res.message); // Show error if API call fails
      }
    });
  };

 

  useEffect(() => {
    getUsers();
  }, []);

  



  const handleBdiAccess = (data: any) => {
    const userdata:any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const payloadData = {
      isVerify: true,
      accessByBdi: userRole.email,
      cEmail: data.email,
    };
    AdminService.updateConversionList(payloadData).then((res: any) => {
      if (res.status === "Success") {
        
      } else {
        toast.error(res.message);
      }
    });
  };

  const getUsers = () => {
    const userdata:any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const payloadData = {
      isVerify: true,
      accessByBdi: userRole.email,
     
    };
    AdminService.getConversions(payloadData).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res?.data.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A"),
        }));
        setUsersData(formattedData);
      } else {
        toast.error(res.message);
      }
    });
  };

  console.log("usersdata",usersData)

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 mt-3">
            <label className="form-label d-flex justify-content-start text-start">
              <b> Conversion List</b>
            </label>
            <div className="d-flex">
              <input
                name="User Name"
                onChange={(e: any) => setName(e.target.value)}
                className="form-control mt-1 me-2"
                value={name}
                placeholder="Enter the EmailId / Phone No"
              />
              <button
                className="btn btn-primary rounded px-4 py-1"
                onClick={handleSearch}
              >
                Search
              </button>
              <button
                className="btn btn-secondary rounded ms-2 px-4 py-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <DataTable
            tableData={usersData}
            TableCols={ConversionsDataGridCols}
            handleBdiAccess={handleBdiAccess}
          />
        </div>
      </div>
    </div>
  );
};

export default ConversionList;
