import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { courses } from "../../../config";

const EditLmsVideos = () => {
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState<any>([]);
  const location = useLocation();
  const { state } = location;
  const [formData, setFormData] = useState<any>({
    title: "",
    courseName: "",
    uploadVideo: [
      {
        topicName: "",
        url: "", // URL field
      },
    ],
    batchName: "",
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (state) {
      setFormData(state);
    }
  }, [state]);

  // Handle input changes for all fields including URL
  const handleInput = (e: any, index?: number) => {
    const { name, value } = e.target;
    console.log("Input Changed:", index, name, value);

    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    setFormData({ ...formData, [name]: selectedOption.value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    getMeets();
  }, []);

  const getMeets = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllBatch(data).then((res: any) => {
      if (res.status === "Success") {
        setBatchData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const bOptions = batchData?.map((type: any) => ({
    value: type.batchName,
    label: type.batchName,
  }));

  // Validate the form inputs
  const validate = () => {
    const newErrors: any = {};

    if (!formData.title) {
      newErrors.title = "Title is required";
    }

    if (!formData.courseName) {
      newErrors.courseName = "Course name is required";
    }

    // Check if any topicName or url fields are missing
    formData.uploadVideo.forEach((category: any, index: number) => {
      if (!category.topicName) {
        newErrors[`topicName-${index}`] = `Topic name for category ${index + 1} is required`;
      }
      if (!category.url) {
        newErrors[`url-${index}`] = `Video URL for category ${index + 1} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!validate()) return;
    formData.lmsId = state._id;


    AdminService.editLms(formData)
      .then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/support/lmsvideolist");
        }
      })
      .catch((err) => {
        toast.error("An error occurred while creating LMS Video");
      });
  };

  // Handle cancel action
  const handleCancel = () => {
    navigate("/support/lmsvideolist");
  };

  // Add a new category to the uploadVideo array
  const addCategory = () => {
    setFormData({
      ...formData,
      uploadVideo: [
        ...formData.uploadVideo,
        {
          topicName: "",
          url: "", // Add a new URL field
        },
      ],
    });
  };

  // Remove a category from the uploadVideo array
  const removeCategory = (index: number) => {
    const newCurriculum = formData.uploadVideo.filter(
      (category: any, catIndex: any) => catIndex !== index
    );
    setFormData({
      ...formData,
      uploadVideo: newCurriculum,
    });
  };

  const handleTopicsChange = (index: number, e: any) => {
    const { name, value } = e.target;
  
    const newCurriculum = [...formData.uploadVideo];
  
    // Ensure that topicName and url are updated correctly based on input field
    if (name === "topicName") {
      newCurriculum[index].topicName = value;
    } else if (name === "url") {
      newCurriculum[index].url = value;  // Keep URL as a string
    }
  
    setFormData({
      ...formData,
      uploadVideo: newCurriculum,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Edit LMS Videos</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Add title</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.title ? "is-invalid" : ""
                    }`}
                    placeholder="Add title"
                    name="title"
                    onChange={handleInput}
                    value={formData.title}
                  />
                  {errors.title && (
                    <div className="invalid-feedback">{errors.title}</div>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Batch Name</label>
                  <Select
                    options={bOptions}
                    name="batchName"
                    placeholder="Select Batch"
                    onChange={handleSelectChange}
                  />
                  {errors.batchName && (
                    <div className="invalid-feedback">{errors.batchName}</div>
                  )}
                </div>
              </div>

              {formData.uploadVideo.map((category: any, index: number) => (
                <div className="col-md-12 pt-2 pb-2" key={index}>
                  <label htmlFor={`topicName-${index}`}>
                    Category {index + 1}
                  </label>
                  <input
                    type="text"
                    name="topicName"
                    id={`topicName-${index}`}
                    className={`form-control ${
                      errors[`topicName-${index}`] ? "is-invalid" : ""
                    }`}
                    value={category.topicName}
                    onChange={(e) => handleTopicsChange(index, e)}
                  />
                  {errors[`topicName-${index}`] && (
                    <div className="invalid-feedback">
                      {errors[`topicName-${index}`]}
                    </div>
                  )}

                  <label htmlFor={`url-${index}`}>Video URL</label>
                  <input
                    className={`form-control ${
                      errors[`url-${index}`] ? "is-invalid" : ""
                    }`}
                    name="url"
                    value={category.url}
                    onChange={(e) => handleTopicsChange(index, e)}
                    placeholder="Enter Video URL"
                  />
                  {errors[`url-${index}`] && (
                    <div className="invalid-feedback">
                      {errors[`url-${index}`]}
                    </div>
                  )}

                  <button
                    type="button"
                    className="btn btn-danger mt-2"
                    onClick={() => removeCategory(index)}
                  >
                    Remove Category
                  </button>
                </div>
              ))}

              <div className="col-md-12 pt-2 pb-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addCategory}
                >
                  Add Category
                </button>
              </div>

              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Course Name</label>
                  <select
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    onChange={handleInput}
                    name="courseName"
                  >
                    <option value="">{formData?.courseName}</option>
                    {courses.map((ele: any, index: number) => (
                      <option value={ele.name} key={index}>
                        {ele.name}
                      </option>
                    ))}
                  </select>
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditLmsVideos;
