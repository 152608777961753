import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { BatchDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const BatchList = () => {
  const [name, setName] = useState("");
  const [meetsData, setMeetsData] = useState([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
  };
  const handleSearch = () => {};
  const handleCreateMeet = () => {
    navigate("/support/createbatch");
  };
  useEffect(() => {
    getMeets();
  }, []);

  const getMeets = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllBatch(data).then((res: any) => {
      if (res.status === "Success") {
        setMeetsData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteMeetInfo = (meetId: string) => {
    const payload = {
      batchId: meetId,
    };
    AdminService.deleteBatch(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getMeets();
      } else {
        toast.error(res.message);
      }
    });
  };
  const handleEditMeet = (data: any) => {
    navigate("/support/editbatch", { state: data });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Batch List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the Batch Name"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="text-end">
                  <button
                    className="btn btn-success"
                    onClick={handleCreateMeet}
                  >
                    Create Batch
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={meetsData}
                TableCols={BatchDataGridCols}
                deleteMeetInfo={deleteMeetInfo}
                handleEditMeet={handleEditMeet}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchList;
