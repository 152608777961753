import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";

import { DataTable } from "../../../components/tables";
import { CertificateDataGridCols } from "./CertificateDataGridCols";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";



const CertificateList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState<any>([]);
  const [formData,setFormData]=useState({
    startDate:'',
    endDate:'',
    gender:'',
  })

  const handleCancel = () => {
    setName("");
  };
  const handleSearch = () => {};
  const handleLmsCreateMeet = () => {
    navigate("/support/createcertificate");
  };

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getallCertificatesList(data).then((res: any) => {
      if (res.status === "Success") {
        setUsersData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };
  // const generateCertificate = (type: string, data: any) => {
  //   const payload = {
  //     certificateEmail: data.email,
  //     certificateType: type,
  //     certificateUrl: `${type}.pdf`,
  //     userName: data.firstName,
  //     certificatePeriod: "may 2013",
  //     courseName: data?.courseName[0],
  //   };
  //   AdminService.createCertificate(payload).then((res: any) => {
  //     if (res.status === "Success") {
  //       toast.success(res.message);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };
  const generateCertificate = async (type: string, data: any) => {
    const { firstName, courseName, createdAt } = data;
    const date = new Date(createdAt).toLocaleDateString();
    try {
      let payload={
        certificateEmail: data.email,
        certificateType: type,
        certificateUrl: [],
        userName:firstName,
        certificatePeriod: "May 2013",
        courseName: courseName[0],
        startDate:formData.startDate,
        endDate:formData.endDate,
        gender:formData.gender,
        
      }


      const res = await AdminService.createCertificate(payload);
      if (res.status === "Success") {
        toast.success(res.message);
       
      } else {
        toast.error(res.message);
      
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
      toast.error("An error occurred while generating the certificate.");
    } finally {

    }
  };


  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-6 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Certificates List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the Title"
                  />
                  <button
                    className="btn btn-primary rounded px-4 py-1"
                    onClick={() => handleSearch()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={usersData}
                TableCols={CertificateDataGridCols}
                generateCertificate={generateCertificate}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateList;
