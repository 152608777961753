import React, { useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

const CreatePrivateCerificate = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    startDate: "",
    endDate: "",
    gender: "",
    courseName: "",
    userName: "",
    certificateEmail: "",
  });
  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.startDate) {
      newErrors.startDate = "Start Date is required";
    }
    if (!formData.gender) {
      newErrors.gender = "Gender is required";
    }

    if (!formData.courseName) {
      newErrors.courseName = "Course Name is required";
    }

    if (!formData.endDate) {
      newErrors.endDate = "End time is required";
    }

    if(!formData.certificateEmail){
      newErrors.certificateEmail="Email is required";
    }

    if (!formData.userName) {
      newErrors.userName = "Name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async(e: any) => {
    console.log("hhhh",validate())
    e.preventDefault();
    // if (validate()) return;

    const payload = {
      startDate: formData.startDate,
      endDate: formData.endDate,
      gender: formData.gender,
      courseName: formData.courseName,
      userName: formData.userName,
      certificateEmail: formData.certificateEmail,
      certificateType:'InternshipCertificate',
      certificateUrl:[],
      certificatePeriod: "May 2013",
      privateCerificate:'adminRelase'
    };
    try{
    const res = await AdminService.createCertificate(payload);
    if (res.status === "Success") {
      toast.success(res.message);
    } else {
      toast.error(res.message);
    
    }
  } catch (error) {
    console.error("Error generating certificate:", error);
    toast.error("An error occurred while generating the certificate.");
  } finally {

  }
   


   
  };

  const handleCancel = () => {
    navigate("/admin/privateCerificate");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Create Cerificate</b>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.userName ? "is-invalid" : ""
                    }`}
                    placeholder="Add Name"
                    name="userName"
                    onChange={handleInput}
                  />
                  {errors.userName && (
                    <div className="invalid-feedback">{errors.userName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Email *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.certificateEmail ? "is-invalid" : ""
                    }`}
                    placeholder="Add Email"
                    name="certificateEmail"
                    onChange={handleInput}
                  />
                  {errors.certificateEmail && (
                    <div className="invalid-feedback">{errors.certificateEmail}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Course Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.courseName ? "is-invalid" : ""
                    }`}
                    placeholder="Add CourseName"
                    name="courseName"
                    onChange={handleInput}
                  />
                  {errors.courseName && (
                    <div className="invalid-feedback">{errors.courseName}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Start Date *</label>
                  <input
                    type="date"
                    className={`form-control mt-2 ${
                      errors.startDate ? "is-invalid" : ""
                    }`}
                    name="startDate"
                    onChange={handleInput}
                  />
                  {errors.startDate && (
                    <div className="invalid-feedback">{errors.startDate}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">End Date *</label>
                  <input
                    type="date"
                    className={`form-control mt-2 ${
                      errors.endDate ? "is-invalid" : ""
                    }`}
                    name="endDate"
                    onChange={handleInput}
                  />
                  {errors.endDate && (
                    <div className="invalid-feedback">{errors.endDate}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Gender *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.gender ? "is-invalid" : ""
                    }`}
                    placeholder="Add Gender"
                    name="gender"
                    onChange={handleInput}
                  />
                  {errors.gender && (
                    <div className="invalid-feedback">{errors.gender}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button type="submit" className="btn btn-primary me-2">
                Create
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreatePrivateCerificate;
