import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/tables";
import { UsersDataGridCols } from "./data-grid-cols";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const UsersList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [usersData, setUsersData] = useState<any>([]);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const navigate = useNavigate();

  const handleCancel = () => {
    setName("");
    setEmail("");
    setPhone("");
    setFilteredUsers(usersData);
  };

  const handleSearch = () => {
    const filteredData = usersData.filter((student: any) => {
      const nameMatch =
        student.firstName.toLowerCase().includes(name.toLowerCase()) ||
        student.lastName.toLowerCase().includes(name.toLowerCase());

      const emailMatch = email
        ? student.email.toLowerCase().includes(email.toLowerCase())
        : true;

      const phoneMatch = phone ? student.phone.includes(phone) : true;

      return nameMatch && emailMatch && phoneMatch;
    });

    setFilteredUsers(filteredData);
  };

  const handleCreateUser = () => {
    navigate("/admin/createuser");
  };

  useEffect(() => {
    getUsers();
  }, []);

  const deleteInfo = (userId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete User",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          userId: userId,
        };
        AdminService.deleteUser(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getUsers();
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditUser = (data: any) => {
    navigate("/admin/editUser", { state: data });
  };

  const getUsers = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllUserDetails(data).then((res: any) => {
      if (res.status === "Success") {
        setUsersData(res.data);
        setFilteredUsers(res.data); // Initialize filteredUsers with all data
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="container-fluid">
      <div className="text-end mt-5">
              <button className="btn btn-success" onClick={handleCreateUser}>
                Create User
              </button>
            </div>
        <div className="row">
          <div className="col-md-10 mt-3">
          
            <label className="form-label d-flex justify-content-start text-start">
              <b> Users List</b>
            </label>
            <div className="d-flex">
              <input
                name="User Name"
                onChange={(e: any) => setName(e.target.value)}
                className="form-control mt-1 me-2"
                value={name}
                placeholder="Enter the User Name"
              />
              <input
                type="email" // Email input
                name="Email"
                onChange={(e: any) => setEmail(e.target.value)}
                className="form-control mt-1 me-2"
                value={email}
                placeholder="Enter the Email"
              />
              <input
                type="phone" // Phone input
                name="Phone"
                onChange={(e: any) => setPhone(e.target.value)}
                className="form-control mt-1 me-2"
                value={phone}
                placeholder="Enter the Phone"
              />

              <button
                className="btn btn-primary rounded px-4 py-1 ms-3"
                onClick={handleSearch}
              >
                Search
              </button>
              <button
                className="btn btn-secondary rounded ms-2 px-4 py-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        
        <div className="mt-5">
          <DataTable
            tableData={filteredUsers}
            TableCols={UsersDataGridCols}
            handleDeleteUser={deleteInfo}
            handleEditUser={handleEditUser}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersList;
